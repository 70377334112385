import React, { createContext, useState, useEffect } from "react";
import { encrypt, decrypt } from "../utils/crypto.js"; // Adjust the import path accordingly

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({
    userId: "",
    userName: "",
    userPhone: "",
    userEmail: "",
  });

  useEffect(() => {
    // On app load, check localStorage for encrypted user data and decrypt it
    const storedEncryptedUserData = localStorage.getItem("userData");
    if (storedEncryptedUserData) {
      const decryptedUserData = decrypt(storedEncryptedUserData);
      setUser(decryptedUserData);
    }
  }, []);

  const saveUser = (userData) => {
    setUser(userData);
    // Encrypt user data before saving to localStorage
    const encryptedUserData = encrypt(userData);
    localStorage.setItem("userData", encryptedUserData);
  };

  return (
    <UserContext.Provider value={{ user, setUser: saveUser }}>
      {children}
    </UserContext.Provider>
  );
};
