import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/userContext";
import Button from "../helpers/Button";
import Loader from "../helpers/Loader";
import {
  changePassword,
  customerSearch,
  token,
  updateCustomer,
} from "../../apiRoutes/api";
import { useNavigate } from "react-router-dom";
import useAuthRedirect from "../../Hooks/useAuthRedirect";
import { editUser, getCustomer } from "../../utils/customers/customerData";
import { toast } from "sonner";
import axios from "axios";
import { getToken } from "../../utils/tokenUtil";

const AdminForm = () => {
  //used custom hook
  useAuthRedirect();

  const { user, setUser } = useContext(UserContext);
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [type, setType] = useState("password");
  const [show, setShow] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [userName, setUserName] = useState(user?.userName);
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [nameHide, setNameHide] = useState("");
  const [passwordHide, setPasswordHide] = useState("hidden");

  const handleEditClick = () => {
    setIsEditable(true);
  };

  const handleEditPassword = () => {
    setIsEditable(true);
    setNameHide("hidden");
    setPasswordHide("");
  };

  const handleShow = () => {
    setShow(!show);
    setType(show ? "text" : "password");
  };

  const fetchData = async () => {
    const payload = {
      name: "",
      phone: user?.userPhone,
      email: user?.userEmail,
      userRoleId: 2,
      pageNo: 1,
      pageSize: 1,
    };
    setIsLoading(true);
    try {
      const result = await getCustomer(customerSearch, payload);

      if (result?.data?.data) {
        const fetchedUserData = result.data.data[0];
        setData(fetchedUserData);
        // Update the user context with the fetched user data
        setUser((prevUser) => ({
          ...prevUser,
          userName: fetchedUserData?.name ? fetchedUserData?.name : "",
        }));
      } else {
        setData(null);
        console.error("No data found in result.");
      }
    } catch (error) {
      if (error.response) {
        // Server responded with a status other than 2xx
        console.error("Server responded with an error:", error.response.data);
        if (error.response.status === 401) {
          sessionStorage.removeItem(token);
          navigate("/login");
        } else {
          alert(
            `Error: ${error.response.data.message || error.response.status}`
          );
        }
      } else if (error.request) {
        // Request was made but no response received
        console.error("No response received:", error.request);
      } else {
        // Something else caused the error
        console.error("Error in setting up the request:", error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [navigate]);

  const handleSave = async () => {
    if (!userName) {
      toast.error("Please Fill The User Name");
      return;
    }
    const payload = {
      name: userName,
      phone: user?.userPhone,
      email: user?.userEmail,
      password: "",
      dob: data?.dob,
      deviceId: data?.deviceId,
      isPhoneVerified: data?.isPhoneVerified,
      isEmailVerified: data?.isEmailVerified,
      isSubscribedForPpromotion: data?.isSubscribedForPpromotion,
      allowKML: data?.allowKML,
      allowRS: data?.allowRS,
      allowLRS: data?.allowLRS,
      allowOnlineMap: data?.allowOnlineMap,
      userId: data?.userId,
      roleId: data?.userRoleId,
      source: "UpdateFromAdminPanel",
    };
    setIsLoading(true);
    try {
      const result = await editUser(updateCustomer, payload);

      if (result && result?.statusCode === 200) {
        toast.success("Customer Updated Successfully");
        setIsEditable(false);
        fetchData();
        // onClose(); // Close the modal or perform any other action
      } else {
        toast.error(result && result?.message);
      }
    } catch (error) {
      console.error("Error Updating Customer:", error);
      toast.error("An Error Occurred. Please Try Again");
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangePassword = async () => {
    const setToken = getToken();
    if (!setToken) {
      return;
    }
    if (!password || !newPassword || !confirmPassword) {
      toast.error("Please Fill All The Fields");
      return;
    }
    if (newPassword.length < 8) {
      toast.error("Password Must Be At Least 8 Characters Long");
      return;
    }

    if (newPassword !== confirmPassword) {
      toast.error("New Password And Confirm Password Do Not Match");
      return;
    }
    const payload = {
      userId: data?.userId,
      oldPassword: password,
      newPassword: newPassword,
      confirmPassword: confirmPassword,
    };

    try {
      const response = await axios.post(changePassword, payload, {
        headers: {
          Authorization: `Bearer ${setToken}`, // Replace with your auth token if needed
          "Content-Type": "application/json",
        },
      });

      console.log(response);

      if (response?.data?.statusCode === 200) {
        // Success response handling
        toast.success("Password Changed Successfully");
        fetchData();
        setIsEditable(false);
        setPassword("");
        setNewPassword("");
        setConfirmPassword("");
        setNameHide("");
        setPasswordHide("hidden");
      } else {
        // Handle other status codes or error handling
        toast.error(response && response?.data?.message);
      }
    } catch (error) {
      if (error.response) {
        // The server responded with a status other than 2xx
        console.error("Error response:", error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        console.error("No response received:", error.request);
      } else {
        // Something went wrong in setting up the request
        console.error("Error:", error.message);
      }
    }
  };

  const handleClose = () => {
    setIsEditable(false); // Reset the editing state
    setUserName(data?.name); // Optionally reset the input fields
    setPasswordHide("hidden");
    setNameHide("");
    setPassword("");
    setNewPassword("");
    setConfirmPassword("");
  };

  return (
    <div className="flex justify-center">
      {isLoading ? (
        <Loader />
      ) : (
        <div className="bg-slate-200 w-full lg:w-2/3 px-5 lg:px-10 py-5 my-5 rounded-lg">
          <div className="font-bold text-2xl text-center my-5">
            Admin Profile
          </div>
          <hr />
          <div className={`${nameHide} my-2`}>
            <div className="grid grid-cols-4 items-center">
              <div className="font-bold">Name</div>
              {isEditable ? (
                <input
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                  className="bg-slate-50 border border-black px-3 py-2 rounded-lg w-full col-span-3"
                />
              ) : (
                <input
                  value={data?.name}
                  readOnly
                  className="bg-slate-300 px-3 py-2 rounded-lg w-full col-span-3"
                />
              )}
            </div>
          </div>
          <div className={`${nameHide} my-2`}>
            <div className="grid grid-cols-4 items-center">
              <div className="font-bold">Phone Number</div>
              <div className="bg-slate-300 px-3 py-2 rounded-lg w-full col-span-3">
                {user.userPhone}
              </div>
            </div>
          </div>
          <div className={`${nameHide} my-2`}>
            <div className="grid grid-cols-4 items-center">
              <div className="font-bold">Email</div>
              <div className="bg-slate-300 px-3 py-2 rounded-lg w-full col-span-3">
                {user.userEmail}
              </div>
            </div>
          </div>
          <div className={`${passwordHide} my-2`}>
            <div className="grid grid-cols-4 items-center">
              <div className="font-bold">Old Password</div>
              {isEditable && (
                <div className="col-span-3 grid sm:grid-cols-4 space-y-2 sm:space-y-0 items-center">
                  <input
                    type={type}
                    onChange={(e) => setPassword(e.target.value)}
                    className="col-span-4 bg-slate-50 px-2 py-2 rounded-lg border border-slate-400"
                  />
                  <div
                    className="place-self-end absolute py-2 px-2 rounded-lg text-center cursor-pointer"
                    onClick={handleShow}
                  >
                    {show ? (
                      <i className="text-xl fa-regular fa-eye"></i>
                    ) : (
                      <i className="fa-regular fa-eye-slash"></i>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={`${passwordHide} my-2`}>
            <div className="grid grid-cols-4 items-center">
              <div className="font-bold">New Password</div>
              {isEditable && (
                <div className="col-span-3 grid sm:grid-cols-4 space-y-2 sm:space-y-0 items-center">
                  <input
                    type={type}
                    onChange={(e) => setNewPassword(e.target.value)}
                    className="col-span-4 bg-slate-50 px-2 py-2 rounded-lg border border-slate-400"
                  />
                  <div
                    className="place-self-end absolute py-2 px-2 rounded-lg text-center cursor-pointer"
                    onClick={handleShow}
                  >
                    {show ? (
                      <i className="text-xl fa-regular fa-eye"></i>
                    ) : (
                      <i className="fa-regular fa-eye-slash"></i>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={`${passwordHide} my-2`}>
            <div className="grid grid-cols-4 items-center">
              <div className="font-bold">Confirm Password</div>
              {isEditable && (
                <div className="col-span-3 grid sm:grid-cols-4 space-y-2 sm:space-y-0 items-center">
                  <input
                    type={type}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="col-span-4 bg-slate-50 px-2 py-2 rounded-lg border border-slate-400"
                  />
                  <div
                    className="place-self-end absolute py-2 px-2 rounded-lg text-center cursor-pointer"
                    onClick={handleShow}
                  >
                    {show ? (
                      <i className="text-xl fa-regular fa-eye"></i>
                    ) : (
                      <i className="fa-regular fa-eye-slash"></i>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="mt-10 flex justify-center lg:justify-end gap-3">
            <button
              onClick={isEditable ? handleSave : handleEditClick}
              className={`${nameHide} cursor-pointer`}
            >
              <Button
                name={isEditable ? "Save" : "Edit"}
                color="bg-[#0866ff]"
                hover="hover:bg-[#0875ff]"
              />
            </button>
            <button
              onClick={
                isEditable && nameHide === "hidden"
                  ? handleChangePassword
                  : handleEditPassword
              }
              className="cursor-pointer"
            >
              <Button
                name={
                  isEditable && nameHide === "hidden"
                    ? "Save"
                    : "Change Password"
                }
                color="bg-[#0866ff]"
                hover="hover:bg-[#0875ff]"
              />
            </button>
            <button
              onClick={handleClose} // Define this function to handle the closing logic
              className={`${nameHide === "" && "hidden"} cursor-pointer`}
            >
              <Button
                name="Close"
                color="bg-red-500" // You can customize the color as needed
                hover="hover:bg-red-600"
              />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminForm;
